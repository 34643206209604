import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthUser from '../pageauth/AuthUser';
import { Toaster, toast } from 'sonner';
import PuenteApi from '../pageauth/PuenteApi';
import Mensaje from '../Mensaje';

export const LayoutHomeLogo = () => {
  const {getToken, getRol, getUser} = AuthUser();
  const navigate = useNavigate();
  
  useEffect(() => {
    if(getToken()){
      if(getRol()==="admin"){
        navigate("/adminpanel")
      }else{
        navigate("/Inicio")
      }
    }else{
      navigate("/Inicio")
    }
  }, []);
  return (
    <div>
      <Toaster expand visibleToasts={1} />
      <div class="contenedor"><div id="cargando"></div></div>
      <div style={{display: "flex", justifyContent: "center", height: "800px", alignItems: "center"}}>
        <img src="https://app.lidergassa.com.ar/img/Icono_inicio.png" alt="Image description" style={{display: "block", margin: "0 auto", height: "120px"}} />
      </div>
    </div>
  )
}