import React, { useState } from 'react'
import Mensaje from './Mensaje';

const Cargando = () => {
    const {SetMensaje, BorrarTodosLosMensajes} = Mensaje();

    const StartCargando = (mensaje="Iniciando sesion") => {
        // if (document.getElementById("outlet")) {
        //     document.getElementById("outlet").style.display = "none";
        // }
        // if (document.getElementById("cargando")) {
        //     document.getElementById("cargando").innerHTML = '<center><div className="loading"><img src="/img/Cargando.gif" height="150px" width="auto"></img></div></center>';
        // }
        const mensajeid = SetMensaje("Cargando", mensaje, 99999999);
    }

    const StopCargando = ()=>{
        // if (document.getElementById("outlet")) {
        //     document.getElementById("outlet").style.display = "block";
        // }
        // if (document.getElementById("cargando")) {
        //     document.getElementById("cargando").innerHTML = "";
        // }
        BorrarTodosLosMensajes();
    }
    return ({
        StartCargando,
        StopCargando,
      }
      )
}

export default Cargando