import React, { useEffect } from 'react';
import { HeaderPublic } from '../componentes/headers/HeaderPublic';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthUser from '../pageauth/AuthUser';
import { Toaster, toast } from 'sonner';
import Mensaje from '../Mensaje';

export const LayoutPublic = () => {
  const {getToken, getRol} = AuthUser();
  const navigate = useNavigate();
  useEffect(() => {
      if(getToken()){
        if(getRol()==="admin"){
          navigate("/adminpanel")
        }
      }
    
  },[]);
  return (
    <>
        <Toaster expand visibleToasts={1} />
        {/* <HeaderPublic /> */}
        <div class="contenedor"><div id="cargando"></div></div>
        <div id="outlet">
        <Outlet />
        </div>
        {/* <FooterPublic /> */}
    </>
  )
}