import AuthUser from './AuthUser'
import Config from '../Config';
import Cargando from '../Cargando';
import Mensaje from '../Mensaje';
import { useState } from 'react';

const PuenteApi = () => {
  const {SetMensaje} = Mensaje();
  const {StartCargando, StopCargando} = Cargando()
  const {getToken, getLogout, getUser, getRol, setToken} = AuthUser()


  const CerrarSession = async () => {
        StartCargando("Cerrando sesion");
        const data = {}
        const response = await Config.getLogout(data, getToken())
        if(response.data.sucess){
            getLogout();
            StopCargando();
            return true;
        }else{
            StopCargando();
            SetMensaje("Error", "Error al cerrar sesion", 3000);
            return false;
        }
   }

   const IniciarSession = (emailordni, password) => {
        StartCargando("Iniciando sesion");
        Config.getLogin({emailordni: emailordni, password: password})
        .then(async ({data})=>{
            if(data.sucess){
                if(data.user.roles[0].name == "admin"){
                    setToken(
                        data.user,
                        data.token,
                        data.user.roles[0].name
                    )
                    StopCargando();
                }else{
                    StopCargando();
                    SetMensaje("Error", "Usuario no valido", 3000);
                }
            }else{
                StopCargando();
                const errorMessages = data.error;
                const firstErrorKey = Object.keys(errorMessages)[0];
                const firstErrorMessage = errorMessages[firstErrorKey][0];

                if(firstErrorKey == 0){
                    SetMensaje("Error", data.error, 3000);
                }else{
                    SetMensaje("Error", firstErrorMessage, 3000);
                }
            }
        })
    }

    
  
  return ({
    CerrarSession,
    IniciarSession
  }
  )
}

export default PuenteApi
