import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { HeaderAdmin } from '../componentes/headers/HeaderAdmin';
import { SidebarAdmin } from '../componentes/Cuerpos/CuerposClient/SidebarAdmin';
import AuthUser from '../pageauth/AuthUser';
import { Toaster, toast } from 'sonner';
import { FooterAdmin } from '../componentes/Footer/FooterAdmin';

export const LayoutAdmin = () => {
  const {getRol, getUser} = AuthUser()

  useEffect(()=>{
    if(getRol()!="admin"){
      return <Navigate to={'/'} />
    }
  },[])
  
  return (
    <>
        <Toaster expand visibleToasts={1} />
        <SidebarAdmin />
        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <HeaderAdmin />
        <Outlet />
        <FooterAdmin />
        </main>
    </>
  )
}