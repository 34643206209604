import axios from "axios";
import AuthUser from "./pageauth/AuthUser";
import { useNavigate } from "react-router-dom";

const base_api_url = "https://api1.lidergassa.com.ar/api/v1";
//Route


const ManejoError= (Error, from) =>{
    if(Error == 401){
        localStorage.clear()
        document.location.href = "/";
    }
    // console.log("Error desde: ")
    // console.log(from)
}

export default{
    //AUTH
    getLogin:(data)=>axios.post(`${base_api_url}/auth/login`, data).catch(error =>  ManejoError(error.response.status)),
    getLogout:(data, token)=>axios.post(`${base_api_url}/auth/logout`, data, { headers: { Authorization: `Bearer ${token}`, },}).catch(error =>  ManejoError(error.response.status, "getLogout")),
    GetUser:(dato, token)=>axios.post(`${base_api_url}/admin/getuser`, dato, { headers: { Authorization: `Bearer ${token}`, },}).catch(error =>  ManejoError(error.response.status, "GetUser")),
    EliminarUser:(userid, token)=>axios.delete(`${base_api_url}/admin/usuarios/${userid}`, { headers: { Authorization: `Bearer ${token}`, },}).catch(error =>  ManejoError(error.response.status, "EliminarUsuario")),
    EditarUser:(userid, data, token)=>axios.put(`${base_api_url}/admin/usuarios/${userid}`, data, { headers: { Authorization: `Bearer ${token}`, },}).catch(error =>  ManejoError(error.response.status, "EditarUsuario")),
    ResetPasswordUser:(dato, token)=>axios.post(`${base_api_url}/admin/resetpassword`, dato, { headers: { Authorization: `Bearer ${token}`, },}).catch(error =>  ManejoError(error.response.status, "ResetPassword")),
    GetTarjetaByDNI:(dato, token)=>axios.post(`${base_api_url}/admin/tarjetausuario`, dato, { headers: { Authorization: `Bearer ${token}`, },}).catch(error =>  ManejoError(error.response.status, "gettarjetadni")),
    GetTarjetaByNumber:(dato, token)=>axios.post(`${base_api_url}/admin/tarjeta`, dato, { headers: { Authorization: `Bearer ${token}`, },}).catch(error =>  ManejoError(error.response.status, "gettarjeta")),
    EliminarTarjeta:(id, token)=>axios.delete(`${base_api_url}/admin/administrartarjeta/${id}`, { headers: { Authorization: `Bearer ${token}`, },}).catch(error =>  ManejoError(error.response.status, "EliminarTarjeta")),
    AgregarTarjeta:(dato, token)=>axios.post(`${base_api_url}/admin/agregartarjeta`, dato, { headers: { Authorization: `Bearer ${token}`, },}).catch(error =>  ManejoError(error.response.status, "AgregarTarjeta")),
}