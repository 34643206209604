import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AuthUser from './AuthUser';
import PuenteApi from './PuenteApi';
import Mensaje from '../Mensaje';

const Login = () => {
    const {getToken} = AuthUser()
    const {IniciarSession} = PuenteApi()
    const navigate = useNavigate();
    const {SetMensaje} = Mensaje();

    const onButtonClick_atras = () => {
        navigate('/')
    };

    const   [emailordni, setEMAILORDNI] = useState("");
    const   [password, setPassword] = useState("");
    const [submitCount, setSubmitCount] = useState(0);
    const [lastSubmitTime, setLastSubmitTime] = useState(0);
    
    const validateFields = () => {
        // Verifica si los campos están completos
        if (!emailordni || !password) {
            SetMensaje("Error", "Por favor, completa todos los campos", 3000);
            return false;
        }

        // Verifica el formato del email o DNI (puedes ajustar la regex según sea necesario)
        const emailOrDniPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|^\d{7,8}$/;
        if (!emailOrDniPattern.test(emailordni)) {
            SetMensaje("Error", "Por favor, ingresa un email o DNI válido", 3000);
            return false;
        }

        // Verifica que la contraseña tenga más de 5 caracteres
        if (password.length <= 5) {
            SetMensaje("Error", "La contraseña debe tener más de 5 caracteres", 3000);
            return false;
        }

        // Si todas las validaciones pasan
        return true;
    };

    const submitLogin = async(e) =>{
        e.preventDefault();
        if (validateFields()) {
            const currentTime = Date.now();
            // Verifica el límite de envíos
            if (submitCount >= 3 && (currentTime - lastSubmitTime) < 120000) {
                SetMensaje("Error", "Has alcanzado el límite de envíos. Intenta nuevamente más tarde", 3000);
                return;
            }
            setSubmitCount(prev => prev + 1);
            setLastSubmitTime(currentTime);
            // Aquí puedes realizar la lógica de inicio de sesión
            IniciarSession(emailordni, password);
        }
    }

    useEffect(() => {
        if(getToken()){
            navigate("/")
        }
    },[]);

    return (
        <div>
            <div class="login-container">
                <div class="d-flex align-items-center mb-4">
                    <button type="button"  href="" onClick={ onButtonClick_atras } class="me-3 back-button">
                        <i class="fas fa-arrow-left icon"></i>
                    </button>
                    <h2 class="mb-0 sub-header-text">Inicio de sesión</h2>
                </div>
                <h3 class="mb-4 header-text">Ingresá con tu cuenta LiderGas</h3>
                <form>
                    <div class="mb-3 custom-margin">
                        <input type="text" class="form-control" placeholder="Email o DNI" value={emailordni} onChange={(e)=>setEMAILORDNI(e.target.value)} required></input>
                    </div>
                    <div class="mb-3 custom-margin">
                        <input type="password" class="form-control" placeholder="Contraseña" value={password} onChange={(e)=>setPassword(e.target.value)} required></input>
                    </div>
                    <button onClick={submitLogin} type="button" class="btn btn-danger btn-block w-100 btn-custom btn-danger-custom mb-3">Siguiente</button>
                </form>
            </div>

            <script src="https://kit.fontawesome.com/a076d05399.js" crossorigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
        </div>
    );
  };

export default Login
