import React from 'react';
export const CuerpoPublic_Inicio = () => {
    
  return (
    <div style={styles.container}>
      <img 
        src="https://app.lidergassa.com.ar/img/Icono_inicio.png" 
        alt="Imagen centrada" 
        style={styles.image}
        height={"100px"}
      />
    </div>
  )
}
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Altura completa de la pantalla
    backgroundColor: '#f0f0f0', // Fondo para visualizar mejor
  },
  image: {
    maxWidth: '100%', // Evitar que la imagen se desborde
    maxHeight: '100%', // Evitar que la imagen se desborde
  }
};