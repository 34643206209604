import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Mensaje from '../../../../Mensaje';
import AuthUser from '../../../../pageauth/AuthUser';
import Cargando from '../../../../Cargando';
import Config from '../../../../Config';

export const CuerpoAdminTarjeta = () => {
    const { SetMensaje } = Mensaje();
    const { StartCargando,  StopCargando} = Cargando();
    const {getToken} = AuthUser();
    const navigate = useNavigate();
    
    // Estado para almacenar el DNI a buscar y los resultados de la búsqueda
    const [dni, setDni] = useState('');
    const [tarjeta, setTarjeta] = useState('');
    const [usuarios, setUsuarios] = useState([]);
    const [tarjetas, setTarjetas] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalView, setModalView] = useState('options'); // Controla qué vista mostrar
    const [formData, setFormData] = useState({ dni: '', NumeroTarjeta: '', Nombre: ''});

    // Función para manejar el cambio en el input
    const handleInputChange = (e) => {
        setDni(e.target.value);
    };
    const handleInputChange1 = (e) => {
        setTarjeta(e.target.value);
    };

    // Función para realizar la búsqueda en la API
    const buscarpornumerotarjeta = async () => {
        StartCargando("Cargando");
        try {
            const response = await Config.GetTarjetaByNumber({NumeroTarjeta: tarjeta}, getToken())
            if(response.data.sucess){
                setUsuarios(response.data.user);
                setTarjetas(response.data.tarjetas);
                StopCargando();
            }else{
                StopCargando();
                const errorMessages = response.data.error;
                const firstErrorKey = Object.keys(errorMessages)[0];
                const firstErrorMessage = errorMessages[firstErrorKey][0];

                if(firstErrorKey == 0){
                    SetMensaje("Error", response.data.error, 3000);
                    setUsuarios([]);
                    setTarjetas([]);
                }else{
                    SetMensaje("Error", firstErrorMessage, 3000);
                    setUsuarios([]);
                    setTarjetas([]);
                }
            }
            
        } catch (error) {
            SetMensaje("Error",'Error al realizar la búsqueda', 3000);
            console.error(error);
        }
    };

    const buscarpordni = async () => {
        StartCargando("Cargando");
        try {
            const response = await Config.GetTarjetaByDNI({dni: dni}, getToken())
            if(response.data.sucess){
                setUsuarios(response.data.user);
                setTarjetas(response.data.tarjetas);
                StopCargando();
            }else{
                StopCargando();
                const errorMessages = response.data.error;
                const firstErrorKey = Object.keys(errorMessages)[0];
                const firstErrorMessage = errorMessages[firstErrorKey][0];

                if(firstErrorKey == 0){
                    SetMensaje("Error", response.data.error, 3000);
                    setUsuarios([]);
                    setTarjetas([]);
                }else{
                    SetMensaje("Error", firstErrorMessage, 3000);
                    setUsuarios([]);
                    setTarjetas([]);
                }
            }
            
        } catch (error) {
            SetMensaje("Error",'Error al realizar la búsqueda', 3000);
            console.error(error);
        }
        
    };

    const abrirModal = async (usuario) => {
        setModalVisible(true);
        setModalView('options'); // Resetea la vista del modal
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const eliminarTarjeta = async(id) => {
        StartCargando("Eliminando");
        try {
            const response = await Config.EliminarTarjeta(id, getToken())
            if(response.data.sucess){
                setUsuarios([]);
                setTarjetas([]);
                StopCargando();
                SetMensaje("OK", "Eliminado con exito", 3000);
            }else{
                StopCargando();
                const errorMessages = response.data.error;
                const firstErrorKey = Object.keys(errorMessages)[0];
                const firstErrorMessage = errorMessages[firstErrorKey][0];

                if(firstErrorKey == 0){
                    SetMensaje("Error", response.data.error, 3000);
                }else{
                    SetMensaje("Error", firstErrorMessage, 3000);
                }
            }
            
        } catch (error) {
            SetMensaje("Error",'Error al eliminar tarjeta', 3000);
            console.error(error);
        }
        StopCargando();
    };

    const agregartarjeta = async() => {
        StartCargando("Agregando");
        const data = {
            DNI: formData.dni, 
            NumeroTarjeta: formData.NumeroTarjeta,
            Nombre: formData.Nombre,
        };
        try {
            const response = await Config.AgregarTarjeta(data, getToken())
            if(response.data.sucess){
                setUsuarios([]);
                setTarjetas([]);
                setFormData([]);
                StopCargando();
                SetMensaje("OK", "Agregado con exito", 3000);
                cerrarModal();
            }else{
                StopCargando();
                const errorMessages = response.data.error;
                const firstErrorKey = Object.keys(errorMessages)[0];
                const firstErrorMessage = errorMessages[firstErrorKey][0];

                if(firstErrorKey == 0){
                    SetMensaje("Error", response.data.error, 3000);
                }else{
                    SetMensaje("Error", firstErrorMessage, 3000);
                }
            }
            
        } catch (error) {
            SetMensaje("Error",'Error al eliminar tarjeta', 3000);
            console.error(error);
        }
        StopCargando();
    };

    const handleBuscarClick = () => {
        if (dni && tarjeta) {
            SetMensaje("Error",'Rellene un solo campo', 3000);
            return;
        }
        if (dni) {
            buscarpordni();
        } else if (tarjeta) {
            buscarpornumerotarjeta();
        } else {
            SetMensaje("Error",'Por favor ingresa un DNI o Nº Tarjeta', 3000);
        }
    };

    return (
        <div className="container-fluid py-4">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>Buscar por</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <div className="table-responsive p-0" style={{overflowX:"hidden"}}>
                                <div className="row">
                                    {/* Primer buscador */}
                                    <div className="col-12 col-md-4 mb-2" >
                                        <div className="input-group" style={{left:"10px"}}>
                                            <span className="input-group-text text-body">
                                                <i className="fas fa-search" aria-hidden="true"></i>
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="DNI usuario"
                                                value={dni}
                                                onChange={handleInputChange}
                                                onKeyPress={(e) => e.key === 'Enter' && buscarpordni()}
                                            />
                                        </div>
                                    </div>

                                    {/* Segundo buscador */}
                                    <div className="col-12 col-md-4 mb-2">
                                        <div className="input-group">
                                            <span className="input-group-text text-body">
                                                <i className="fas fa-search" aria-hidden="true"></i>
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Nº tarjeta"
                                                value={tarjeta}
                                                onChange={handleInputChange1}
                                                onKeyPress={(e) => e.key === 'Enter' && buscarpornumerotarjeta()}
                                            />
                                        </div>
                                    </div>

                                    {/* Contenedor de los botones (uno al lado del otro) */}
                            <div className="col-12 col-md-4 d-flex mb-2">
                                {/* Primer botón */}
                                <button
                                    className="btn btn-primary w-50 me-2" // w-50 para 50% de ancho, me-2 para margen entre botones
                                    onClick={(e) => handleBuscarClick()}
                                >
                                    Buscar
                                </button>

                                {/* Segundo botón */}
                                <button
                                    className="btn btn-secondary w-25" // w-50 para 50% de ancho
                                    onClick={(e) => abrirModal()}
                                >
                                    +
                                </button>
                            </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>Resultado búsqueda</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <div className="table-responsive p-0">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Nº Tarjeta</th>
                                            <th className="text-center">Estado</th>
                                            <th className="text-center">Creada/Actualizada</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tarjetas.length > 0 ? (
                                            tarjetas.map((tarjetaa) => (
                                                <tr key={tarjetaa.id}>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div>
                                                                <img src="https://app.lidergassa.com.ar/img/iconUser.png" class="avatar avatar-sm me-3" alt="user1"></img>
                                                            </div>
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{`${usuarios[0].name} ${usuarios[0].apellido}`}</h6>
                                                                <p className="text-xs text-secondary mb-0">{usuarios[0].email}</p>
                                                                <p className="text-xs text-secondary mb-0">{usuarios[0].telefono || 'N/A'}</p>
                                                                <p className="text-xs text-secondary mb-0">{tarjetaa.DNI || 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{tarjetaa.NumeroTarjeta || 'N/A'}</p>
                                                        <p className="text-xs text-secondary mb-0">{tarjetaa.Nombre || 'N/A'}</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className={`badge badge-sm bg-gradient-${tarjetaa.Enable == 1 ? 'success' : 'secondary'}`}>
                                                            {tarjetaa.Enable == 1 ? 'Habilitada' : 'No Habilitada'}
                                                        </span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {/* <span className="text-secondary text-xs font-weight-bold">
                                                            {new Date(tarjetaa.created_at).toLocaleDateString()}
                                                        </span> */}
                                                        <p className="text-xs font-weight-bold mb-0">{new Date(tarjetaa.created_at).toLocaleDateString()}</p>
                                                        <p className="text-xs text-secondary mb-0">{new Date(tarjetaa.updated_at).toLocaleDateString()}</p>
                                                    </td>
                                                    <td className="align-middle">
                                                        <button
                                                            className="btn btn-link text-secondary font-weight-bold text-xs"
                                                            onClick={() => eliminarTarjeta(tarjetaa.NumeroTarjeta)}
                                                        >
                                                            Eliminar
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className="text-center">No se encontraron resultados</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            {modalVisible && (
                <div className="modal d-block" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalView === 'options' ? `Vincular Tarjeta` : 'Formulario'}
                                </h5>
                                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={cerrarModal}></button>
                            </div>
                            <div className="modal-body">
                                {modalView === 'options' ? (
                                    <form>
                                    <div className="mb-3">
                                        <label className="form-label">DNI</label>
                                        <input type="text" className="form-control" name="dni" value={formData.dni} onChange={handleInputChange2} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Nº Tarjeta</label>
                                        <input type="text" className="form-control" name="NumeroTarjeta" value={formData.NumeroTarjeta} onChange={handleInputChange2} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Nombre</label>
                                        <input type="text" className="form-control" name="Nombre" value={formData.Nombre} onChange={handleInputChange2} />
                                    </div>
                                    
                                    <button type="button" className="btn btn-primary" onClick={() => agregartarjeta()}>Guardar</button>
                                </form>
                                ) : (<div></div>)}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
