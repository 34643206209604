import React, { useState, useEffect, useRef } from 'react';

export const SidebarAdmin = () => {
  useEffect(() => {
    // 1. Obtener el path de la URL después de .com.ar
    const url = window.location.pathname;
    const pathSinDominio = url.substring(url.indexOf('/'));
    const div = document.getElementById(''+pathSinDominio.slice(1));
    if (div) {
      div.className = "nav-link active";
    }
  }, []);
  return (
    <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3" id="sidenav-main">
      <div className="sidenav-header">
        <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
        <a className="navbar-brand m-0" href="/adminpanel" rel="noopener noreferrer">
          <img src="https://app.lidergassa.com.ar/img/Icono_inicio.png" className="navbar-brand-img h-100" alt="main_logo" />
          <span className="ms-1 font-weight-bold">Panel Administación</span>
        </a>
      </div>
      <hr className="horizontal dark mt-0" />
      <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main" >
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="/adminpanel" id="adminpanel">
              <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i class="fa fa-home" style={{color: "black"}}></i>
              </div>
              <span className="nav-link-text ms-1">Dashboard</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/adminusuarios" id="adminusuarios">
              <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i class="fa fa-user" style={{color: "black"}}></i>
              </div>
              <span className="nav-link-text ms-1">Usuarios App</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/adminutarjetas" id="adminutarjetas">
              <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i class="fa fa-id-card" aria-hidden="true" style={{color: "black"}}></i>
              </div>
              <span className="nav-link-text ms-1">Tarjetas vinculadas</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/Salir" id="Salir">
              <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i class="fa fa-arrow-left" aria-hidden="true" style={{color: "black"}}></i>
              </div>
              <span className="nav-link-text ms-1">Cerrar Sesion</span>
            </a>
          </li>
          {/* Agregar más enlaces según sea necesario */}
        </ul>
      </div>
    </aside>
  );
};
 
