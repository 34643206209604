import React, { useState, useRef, useEffect  } from 'react';

import AuthUser from '../../../../pageauth/AuthUser';
import PuenteApi from '../../../../pageauth/PuenteApi';
import { useNavigate } from 'react-router-dom';
import Mensaje from '../../../../Mensaje';
export const CuerpoLogin = () => {

    const {SetMensaje} = Mensaje();
    
    const navigate = useNavigate();

    const {} = PuenteApi()
    
    useEffect(() => {
        
    });
        
    const {getUser} = AuthUser()
    const NombreUsuario= getUser().name
    const fecha = new Date();
    const hora = fecha.getHours();
    let saludo;

    if (hora >= 6 && hora < 12) {
        saludo = 'Buenos días';
    } else if (hora >= 12 && hora < 19) {
        saludo = 'Buenas tardes';
    } else {
        saludo = 'Buenas noches';
    }

  return (
    <div>
        <br></br>
        <center>Seccion en construcción</center>
    </div>
  )
}
