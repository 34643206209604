import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Mensaje from '../../../../Mensaje';
import AuthUser from '../../../../pageauth/AuthUser';
import Cargando from '../../../../Cargando';
import Config from '../../../../Config';

export const CuerpoAdminUser = () => {
    const { SetMensaje } = Mensaje();
    const { StartCargando,  StopCargando} = Cargando();
    const {getToken} = AuthUser();
    const navigate = useNavigate();
    
    // Estado para almacenar el DNI a buscar y los resultados de la búsqueda
    const [dni, setDni] = useState('');
    const [usuarios, setUsuarios] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalView, setModalView] = useState('options'); // Controla qué vista mostrar
    const [formData, setFormData] = useState({ name: '', apellido: '', dni: '', telefono: '', email: '', email_verified_at: '', google_id: '' });
    const [formData2, setFormData2] = useState({ id: '', password: ''});

    // Función para manejar el cambio en el input
    const handleInputChange = (e) => {
        setDni(e.target.value);
    };

    // Función para realizar la búsqueda en la API
    const buscarUsuarios = async () => {
        StartCargando("Cargando");
        try {
            const response = await Config.GetUser({dni: dni}, getToken())
            if(response.data.sucess){
                setUsuarios(response.data.data);
                StopCargando();
            }else{
                StopCargando();
                const errorMessages = response.data.error;
                const firstErrorKey = Object.keys(errorMessages)[0];
                const firstErrorMessage = errorMessages[firstErrorKey][0];

                if(firstErrorKey == 0){
                    SetMensaje("Error", response.data.error, 3000);
                    setUsuarios([]);
                }else{
                    SetMensaje("Error", firstErrorMessage, 3000);
                    setUsuarios([]);
                }
            }
            
        } catch (error) {
            SetMensaje("Error",'Error al realizar la búsqueda', 3000);
            console.error(error);
        }
    };

    const abrirModal = async (usuario) => {
        setSelectedUser(usuario);
        setFormData({ name: usuario.name, apellido: usuario.apellido, dni: usuario.dni, telefono: usuario.telefono, email: usuario.email, email_verified_at: formatFechaParaInput(usuario.email_verified_at), google_id: usuario.google_id });
        setModalVisible(true);
        setModalView('options'); // Resetea la vista del modal
    };

    const cerrarModal = () => {
        setModalVisible(false);
        setSelectedUser(null);
        setFormData({ name: '', apellido: '', dni: '', telefono: '', email: '', email_verified_at: '', google_id: '' });
        setFormData2({ id: '', password: ''});
    };

    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleInputChange3 = (e) => {
        const { name, value } = e.target;
        setFormData2({ ...formData, [name]: value });
    };

    const convertirFecha = (fechaLocal) => {
        const fecha = new Date(fechaLocal);
        return fecha.toISOString(); 
    };

    const formatFechaParaInput = (fechaISO) => {
        if (!fechaISO) return '';
        const fecha = new Date(fechaISO);
        return fecha.toISOString().slice(0, 16); // Formato compatible con datetime-local
    };

    const borrarFecha = () => {
        setFormData({ ...formData, email_verified_at: '' });
    };

    const eliminaruser = async(id) => {
        StartCargando("Eliminando");
        try {
            const response = await Config.EliminarUser(id, getToken())
            if(response.data.sucess){
                setUsuarios([]);
                StopCargando();
                SetMensaje("OK", "Eliminado con exito", 3000);
            }else{
                StopCargando();
                const errorMessages = response.data.error;
                const firstErrorKey = Object.keys(errorMessages)[0];
                const firstErrorMessage = errorMessages[firstErrorKey][0];

                if(firstErrorKey == 0){
                    SetMensaje("Error", response.data.error, 3000);
                }else{
                    SetMensaje("Error", firstErrorMessage, 3000);
                }
            }
            
        } catch (error) {
            SetMensaje("Error",'Error al eliminar usuario', 3000);
            console.error(error);
        }
        StopCargando();
        cerrarModal();
    };

    const editaruser = async(id) => {
        StartCargando("Editando");
        const data = {
            id: selectedUser.id, 
            name: formData.name,
            apellido: formData.apellido,
            dni: formData.dni,
            telefono: formData.telefono,
            email: formData.email,
            email_verified_at: formData.email_verified_at ? convertirFecha(formData.email_verified_at) : null,
            google_id: formData.google_id,
        };
        try {
            const response = await Config.EditarUser(selectedUser.id, data, getToken())
            if(response.data.sucess){
                setUsuarios([]);
                StopCargando();
                SetMensaje("OK", "Editado con exito", 3000);
            }else{
                StopCargando();
                const errorMessages = response.data.error;
                const firstErrorKey = Object.keys(errorMessages)[0];
                const firstErrorMessage = errorMessages[firstErrorKey][0];

                if(firstErrorKey == 0){
                    SetMensaje("Error", response.data.error, 3000);
                }else{
                    SetMensaje("Error", firstErrorMessage, 3000);
                }
            }
            
        } catch (error) {
            StopCargando();
            SetMensaje("Error",'Error al editar usuario', 3000);
            console.error(error);
        }
        StopCargando();
        cerrarModal();
    };

    const resetpassword = async(id) => {
        StartCargando("Editando");
        const data = {
            id: selectedUser.id, 
            password: formData2.password,
        };
        try {
            const response = await Config.ResetPasswordUser(data, getToken())
            if(response.data.sucess){
                setUsuarios([]);
                StopCargando();
                SetMensaje("OK", "Editado con exito", 3000);
            }else{
                StopCargando();
                const errorMessages = response.data.error;
                const firstErrorKey = Object.keys(errorMessages)[0];
                const firstErrorMessage = errorMessages[firstErrorKey][0];

                if(firstErrorKey == 0){
                    SetMensaje("Error", response.data.error, 3000);
                }else{
                    SetMensaje("Error", firstErrorMessage, 3000);
                }
            }
            
        } catch (error) {
            StopCargando();
            SetMensaje("Error",'Error al editar usuario', 3000);
            console.error(error);
        }
        StopCargando();
        cerrarModal();
    };

    const renderForm = () => {
        switch (modalView) {
            case 'editUser':
                return (
                    <form>
                        <h5>Editar Usuario</h5>
                        <div className="mb-3">
                            <label className="form-label">Nombre</label>
                            <input type="text" className="form-control" name="name" value={formData.name} onChange={handleInputChange2} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Apellido</label>
                            <input type="text" className="form-control" name="apellido" value={formData.apellido} onChange={handleInputChange2} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">DNI</label>
                            <input type="text" className="form-control" name="dni" value={formData.dni} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Telefono</label>
                            <input type="text" className="form-control" name="telefono" value={formData.telefono} onChange={handleInputChange2} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" name="email" value={formData.email} onChange={handleInputChange2} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Fecha Verificacion Email</label>
                            <input type="datetime-local" className="form-control" name="email_verified_at" value={formData.email_verified_at} onChange={handleInputChange2} />
                            <button
                            type="button"
                            className="btn btn-outline-danger mt-2"
                            onClick={borrarFecha}
                        >Limpiar Fecha</button>
                        </div>
                        
                        <div className="mb-3">
                            <label className="form-label">Google ID</label>
                            <input type="text" className="form-control" name="google_id" value={formData.google_id} onChange={handleInputChange2} />
                        </div>
                        <button type="button" className="btn btn-primary" onClick={() => editaruser(selectedUser?.id)}>Guardar</button>
                    </form>
                );
            case 'changePassword':
                return (
                    <form>
                        <h5>Cambiar Contraseña</h5>
                        <div className="mb-3">
                            <label className="form-label">Nueva Contraseña</label>
                            <input type="password" className="form-control" name="password" onChange={handleInputChange3} />
                        </div>
                        <button type="button" className="btn btn-primary" onClick={() => resetpassword(selectedUser?.id)}>Guardar</button>
                    </form>
                );
            case 'deleteUser':
                return (
                    <div>
                        <h5>Eliminar Usuario</h5>
                        <p>¿Estás seguro de que deseas eliminar al usuario <strong>{selectedUser?.name}</strong>?</p>
                        <button type="button" className="btn btn-danger" onClick={() => eliminaruser(selectedUser?.id)}>Eliminar</button>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="container-fluid py-4">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>Buscar usuarios</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <div className="table-responsive p-0">
                                <div className="input-group" style={{width:"50%", textAlign:"center", left:"20px"}}>
                                    <span className="input-group-text text-body">
                                        <i className="fas fa-search" aria-hidden="true"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="DNI usuario"
                                        value={dni}
                                        onChange={handleInputChange}
                                        onKeyPress={(e) => e.key === 'Enter' && buscarUsuarios()}
                                    />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>Resultado búsqueda</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <div className="table-responsive p-0">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>DNI</th>
                                            <th className="text-center">Estado</th>
                                            <th className="text-center">Última conexión</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usuarios.length > 0 ? (
                                            usuarios.map((usuario) => (
                                                <tr key={usuario.id}>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div>
                                                                <img src="https://app.lidergassa.com.ar/img/iconUser.png" class="avatar avatar-sm me-3" alt="user1"></img>
                                                            </div>
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{`${usuario.name} ${usuario.apellido}`}</h6>
                                                                <p className="text-xs text-secondary mb-0">{usuario.email}</p>
                                                                <p className="text-xs text-secondary mb-0">{usuario.telefono || 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{usuario.dni || 'N/A'}</p>
                                                        <p className="text-xs text-secondary mb-0">{usuario.google_id ? 'Posee Google' : 'No posee Google'}</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className={`badge badge-sm bg-gradient-${usuario.email_verified_at ? 'success' : 'secondary'}`}>
                                                            {usuario.email_verified_at ? 'Verificado' : 'No Verificado'}
                                                        </span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">
                                                            {new Date(usuario.updated_at).toLocaleDateString()}
                                                        </span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <button
                                                            className="btn btn-link text-secondary font-weight-bold text-xs"
                                                            onClick={() => abrirModal(usuario)}
                                                        >
                                                            Edit
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className="text-center">No se encontraron resultados</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            {modalVisible && (
                <div className="modal d-block" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalView === 'options' ? `Opciones para ${selectedUser?.name}` : 'Formulario'}
                                </h5>
                                <button type="button" className="btn-close btn-close-white" onClick={cerrarModal}></button>
                            </div>
                            <div className="modal-body">
                                {modalView === 'options' ? (
                                    <ul>
                                        <li>
                                            <button className="btn btn-primary w-100 mb-2" onClick={() => setModalView('editUser')}>
                                                Editar usuario
                                            </button>
                                        </li>
                                        <li>
                                            <button className="btn btn-secondary w-100 mb-2" onClick={() => setModalView('changePassword')}>
                                                Cambiar contraseña
                                            </button>
                                        </li>
                                        <li>
                                            <button className="btn btn-danger w-100" onClick={() => setModalView('deleteUser')}>
                                                Eliminar usuario
                                            </button>
                                        </li>
                                    </ul>
                                ) : (
                                    renderForm()
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
