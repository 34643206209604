import React, { useEffect } from 'react';
import AuthUser from '../../pageauth/AuthUser';

export const HeaderAdmin = () => {
  const {getUser} = AuthUser()
  const NombreUsuario= getUser().name
  const fecha = new Date();
  const hora = fecha.getHours();
  let saludo;

  if (hora >= 6 && hora < 12) {
      saludo = 'Buenos días';
  } else if (hora >= 12 && hora < 19) {
      saludo = 'Buenas tardes';
  } else {
      saludo = 'Buenas noches';
  }
  
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <nav
      className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
      id="navbarBlur"
      navbar-scroll="true"
    >
      <div className="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <h6 className="font-weight-bolder mb-0"><span class="wave">👋</span> ¡{saludo}, {capitalizeFirstLetter(NombreUsuario)}!</h6>
        </nav>
        <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          <div className="ms-md-auto pe-md-3 d-flex align-items-center">
            
          </div>
          <ul className="navbar-nav justify-content-end">
            <li className="nav-item d-flex align-items-center">
              <a href="/Salir" className="nav-link text-body font-weight-bold px-0">
                <i className="fa fa-user me-sm-1"></i>
                <span className="d-sm-inline d-none">Cerrar Sesion</span>
              </a>
            </li>
            <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  const myDiv = document.getElementById('sidenav-main');
                  
                  if(myDiv){
                    console.log(myDiv.className);
                    if(myDiv.className == "sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3"){
                       myDiv.classList.add('bg-white');
                    }else{
                      myDiv.classList.remove('bg-white');
                      
                    }
                  }

                  const myDiv2 = document.querySelector('.g-sidenav-show:not(.rtl) .sidenav');
                  if(myDiv2){
                    console.log(myDiv2.style.transform);
                    if(myDiv2.style.transform == 'translateX(0px)'){
                      myDiv2.style.transform = 'translateX(-17.125rem)';
                    }else{
                      myDiv2.style.transform = 'translateX(0px)';
                    }
                  }
              }}
                className="nav-link text-body p-0"
                id="iconNavbarSidenav"
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

