const { useNavigate } = require("react-router-dom");

let app, analytics, messaging, SetMensaje, StartCargando, StopCargando;

const isIPhone = /iPhone/i.test(navigator.userAgent);

if (process.env.NODE_ENV === 'production' || (process.env.NODE_ENV === 'development' && !isIPhone)) {
  // Import the functions you need from the SDKs you need
  // Usar require para importar solo en producción
  const { initializeApp } = require("firebase/app");
  const { getAnalytics } = require("firebase/analytics");
  const { getMessaging, getToken, onMessage, deleteToken } = require("firebase/messaging");
  const Mensaje = require("./Mensaje").default;
  const Config = require("./Config").default;
  const Cargando = require("./Cargando").default;
  const Swal = require('sweetalert2');
  
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyCvCWX0vYdyU3o14Qjz6Roi0991JmQE0LI",
    authDomain: "app-lidergas-sa.firebaseapp.com",
    projectId: "app-lidergas-sa",
    storageBucket: "app-lidergas-sa.appspot.com",
    messagingSenderId: "60803896962",
    appId: "1:60803896962:web:785daa63f6b10dda8bc69b",
    measurementId: "G-5W07MBTXGZ"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const messaging = getMessaging(app);

  const {SetMensaje} = Mensaje();
  const {StartCargando, StopCargando} = Cargando()


  let isRequestingToken = false;  // Variable global para evitar solicitudes duplicadas

exports.requestForToken = async () => {
  // Verificamos si ya tenemos un token almacenado en localStorage
  const storedToken = localStorage.getItem('fcm_token');
  if (storedToken) {
    // Si ya existe un token, no necesitamos volver a solicitar uno
    console.log('Token ya almacenado:', storedToken);
    return storedToken;
  }

  // Si no se está solicitando un token, lo pedimos
  if (isRequestingToken) {
    return;  // Evitamos hacer más de una solicitud de token a la vez
  }

  try {
    isRequestingToken = true;  // Marcamos que estamos solicitando el token

    // Mostrar popup con SweetAlert2 para preguntar al usuario
    const result = await Swal.fire({
      title: 'Notificaciones',
      text: "¿Te gustaría recibir notificaciones sobre tus puntos y premios?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, quiero recibir',
      cancelButtonText: 'No, gracias',
      reverseButtons: true,
      customClass: {
        popup: 'popup-custom-class' // Puedes personalizar el estilo CSS aquí
      },
    });

    // Si el usuario acepta recibir notificaciones
    if (result.isConfirmed) {
      const token = await getToken(messaging, { vapidKey: "BGGnMVJTkNnbX1qjHffIfNJzDXfjiYAddEvqv0BqM4cd9Gt-10J6oVy98E8jbkY5bPjg5Egm-_z5MO5XDzkUBbQ" });

      if (token) {
        localStorage.setItem('fcm_token', token);  // Guardamos el token en localStorage
        StartCargando("Activando notificación");

        const userlogintokenString = localStorage.getItem('token');
        const userlogintoken = JSON.parse(userlogintokenString);

        const response = await Config.AddTokenNotificacion({ token: token }, userlogintoken);

        if (response.data.sucess) {
          StopCargando();
        } else {
          StopCargando();
          SetMensaje("Error", "Error al activar notificaciones", 3000);
        }
      } else {
        console.log('No se pudo generar el token.');
      }
    }
  } catch (error) {
    console.error('Error obteniendo token FCM', error);
  } finally {
    isRequestingToken = false;  // Restablecemos la variable para permitir futuras solicitudes si es necesario
  }
};

  const openDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('NotificationsDB', 1); // Nombre de la base de datos
  
      request.onerror = (event) => {
        reject('Error al abrir la base de datos');
      };
  
      request.onsuccess = (event) => {
        resolve(event.target.result); // Devuelve la base de datos abierta
      };
  
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        // Si no existe el object store 'notifications', lo creamos
        if (!db.objectStoreNames.contains('notifications')) {
          db.createObjectStore('notifications', { keyPath: 'id', autoIncrement: true });
        }
      };
    });
  };

  function getNotifications() {
    return new Promise((resolve, reject) => {
      openDB().then(db => {
        const transaction = db.transaction(['notifications'], 'readonly');
        const store = transaction.objectStore('notifications');
        const request = store.getAll(); // Recuperar todas las notificaciones
  
        request.onsuccess = (event) => {
          resolve(event.target.result); // Resolver con las notificaciones
        };
  
        request.onerror = (event) => {
          reject('Error al obtener las notificaciones');
        };
      });
    });
  }
  
  function saveNotification(notification) {
    openDB().then(db => {
      const transaction = db.transaction(['notifications'], 'readwrite');
      const store = transaction.objectStore('notifications');
      
      // Insertar la nueva notificación
      store.add(notification);
  
      // Cuando la notificación se guarda, podemos controlar la cantidad de notificaciones
      store.getAll().onsuccess = (event) => {
        const notifications = event.target.result;
  
        // Si el número de notificaciones supera 10, eliminamos la más antigua
        if (notifications.length > 10) {
          // Eliminar la notificación más antigua (la primera en el array)
          const firstNotification = notifications[0];
          store.delete(firstNotification.id); // Eliminar la primera notificación por su ID
        }
  
        // Actualizar la UI si es necesario
        const elemnt = document.getElementById("cantNoti");
        if (elemnt) {
          elemnt.innerHTML = notifications.length > 10 ? 10 : notifications.length;
        }
        // window.location.reload();
      };
    });
  }

  function handleNewNotification(notification) {
    saveNotification(notification);
  }
  
  const updateappbadge = async (numeronotificaciones) => {
    await navigator.setAppBadge(numeronotificaciones);
    // if (window.BadgeInterface) {
    //   window.BadgeInterface.setBadgeCount(numeronotificaciones); // 5 es el número de notificaciones
    // }
  };

  exports.onMessageListener = () =>
    {
      onMessage(messaging, message=>{
          SetMensaje("Informacion", message.data.body, 3000);
          getNotifications()
          .then(notifications => {
              updateappbadge(notifications.length);
              const elemnt = document.getElementById("cantNoti");
              if(elemnt){
                elemnt.innerHTML=notifications.length;
              }
              if (window.updatenoti) {
                window.updatenoti();  // Llamamos a la función expuesta globalmente
              }
          })
          .catch(error => {
            console.error('Error al obtener las notificaciones:', error);
          });
          // const CantidadActualNotificacionesSinLeer = localStorage.getItem('CantidadNotificacionesSL');
          // var CantidadNueva = 1
          // if (CantidadActualNotificacionesSinLeer){
          //   CantidadNueva = parseInt(CantidadActualNotificacionesSinLeer) +1;
          // }
          // updateappbadge(CantidadNueva);
          // localStorage.setItem('CantidadNotificacionesSL', CantidadNueva);
          // const elemnt = document.getElementById("cantNoti");
          // if(elemnt){
          //   elemnt.innerHTML=CantidadNueva;
          // }
          //handleNewNotification(message);
        })
    };

  //   export const onMessageListener = () =>
  //     new Promise((resolve) => {
  //       onMessage((payload) => {
  //         resolve(payload);
  //       });
  //     });

  exports.unsubscribeFromNotifications = async () => {
      try {
        const token = localStorage.getItem('fcm_token');
        if (token) {
          // Borra el token desde Firebase
          await deleteToken(messaging);

          // Elimina el token también del almacenamiento local
          localStorage.removeItem('fcm_token');

          StartCargando("Desactivando notificacion");
          const userlogintokenString = localStorage.getItem('token');
          const userlogintoken = JSON.parse(userlogintokenString);
          const response = await Config.RemoveTokenNotificacion({token: token}, userlogintoken)
          if(response.data.sucess){
              StopCargando();
          }else{
              StopCargando();
              SetMensaje("Error", "Error al desactivar notificaciones", 3000);
          }
          
        } else {
          console.log("No hay token almacenado para borrar.");
        }
      } catch (error) {
        console.error("Error al borrar el token FCM:", error);
      }
  };
  exports.unsubscribe = async () => {
      try {
        const token = localStorage.getItem('fcm_token');
        if (token) {
          // Borra el token desde Firebase
          await deleteToken(messaging);          
        } else {
          console.log("No hay token almacenado para borrar.");
        }
      } catch (error) {
        console.error("Error al borrar el token FCM:", error);
      }
  };
} else {
  console.log("Firebase no se inicializa en entorno de desarrollo.");
  exports.unsubscribeFromNotifications = async () => {};
  exports.onMessageListener = () =>{};
  exports.requestForToken = async () => {};
  exports.unsubscribe = async () => {};
}

