import './App.css';

import './App.jsx';
import React, { useEffect } from 'react';
import ProtectedRoutes from './pageauth/ProtectedRoutes.jsx';
import { LayoutPublic } from './layouts/LayoutPublic.jsx';
import { LayoutAdmin } from './layouts/LayoutAdmin.jsx';
import { CuerpoPublic_Inicio } from './componentes/Cuerpos/CuerposPublic/CuerpoPublic_Inicio.jsx';
import { CuerpoAdmin_Inicio } from './componentes/Cuerpos/CuerposClient/CuerpoClient_Inicio.jsx';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from './pageauth/Login.jsx';
import { LayoutHomeLogo } from './layouts/LayoutHomeLogo.jsx';
import { CuerpoAdminUser } from './componentes/Cuerpos/CuerposClient/Cuerpo/CuerpoAdminUser.jsx';
import { CuerpoSalir } from './componentes/Cuerpos/CuerposClient/Cuerpo/CuerpoSalir.jsx';
import { CuerpoAdminTarjeta } from './componentes/Cuerpos/CuerposClient/Cuerpo/CuerpoAdminTarjeta.jsx';


function App() {
      
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LayoutHomeLogo />}>
                </Route>
                <Route path="/Inicio" element={<LayoutPublic />}>
                    <Route index element={<CuerpoPublic_Inicio />} />
                </Route>
                <Route path="/Adminlogin" element={<LayoutPublic />}>
                    <Route index element={<Login />} />
                </Route>
                
                <Route element={<ProtectedRoutes/>}>
                    <Route path="/adminpanel" element={<LayoutAdmin />}>
                        <Route index element={<CuerpoAdmin_Inicio />} />
                    </Route>
                    <Route path="/adminusuarios" element={<LayoutAdmin />}>
                        <Route index element={<CuerpoAdminUser />} />
                    </Route>
                    <Route path="/adminutarjetas" element={<LayoutAdmin />}>
                        <Route index element={<CuerpoAdminTarjeta />} />
                    </Route>
                    <Route path="/Salir" element={<LayoutAdmin />}>
                        <Route index element={<CuerpoSalir/>} />
                    </Route>
                </Route>
            </Routes>
        </Router>
    );
}

export default App;

