import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Mensaje from '../../../../Mensaje';
import AuthUser from '../../../../pageauth/AuthUser';
import Cargando from '../../../../Cargando';
import Config from '../../../../Config';
import PuenteApi from '../../../../pageauth/PuenteApi';

export const CuerpoSalir = () => {
    const navigate = useNavigate();
    const {CerrarSession} = PuenteApi();

    useEffect(() => {
        const Salir = async() => {
            const resultado = await CerrarSession();
            if(resultado==true){
                navigate("/Adminlogin")
            }
        }    
        Salir();  
    },[]);

    return (
        <div className="container-fluid py-4">
            <br></br>
            <center>Cerrando sesion...</center>
        </div>
    );
};
