import React, { useState } from 'react'
import { Toaster, toast } from 'sonner';

const Mensaje = () => {

    const SetMensaje = (tipo, mensaje, duracion=9999) => {
        const toastId = toast("");
        if(tipo==="Error"){
            toast.error(mensaje,{
                id: toastId,
                style: {
                    background: '#dc3545',
                    bottom: '65px',
                    left: '1px',
                },
                className: 'class',
                duration: duracion
            })
        }
        if(tipo==="Advertencia"){
            toast.warning(mensaje,{
                id: toastId,
                style: {
                    background: '#ebde00',
                    bottom: '65px',
                    left: '1px',
                },
                className: 'class',
                duration: duracion
            })
        }
        if(tipo==="Cargando"){
            toast.loading(mensaje,{
                id: toastId,
                style: {
                    bottom: '65px',
                    left: '1px',
                },
                duration: duracion
            })
        }
        if(tipo==="OK"){
            toast.success(mensaje,{
                id: toastId,
                style: {
                    background: '#00ae3c',
                    bottom: '65px',
                    left: '1px',
                },
                className: 'class',
                duration: duracion
            })
        }

        if(tipo==="Informacion"){
            toast.info(mensaje,{
                id: toastId,
                style: {
                    bottom: '65px',
                    left: '1px',
                },
                duration: duracion
            })
        }
        
        return {toastId};
    }

    const BorrarMensaje = (id)=>{
        toast.dismiss(id);
    }

    const BorrarTodosLosMensajes = ()=>{
        toast.dismiss();
    }
    return ({
        SetMensaje,
        BorrarMensaje,
        BorrarTodosLosMensajes,
      }
      )
}

export default Mensaje